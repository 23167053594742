<template>
  <v-app>
    <SideNav
      v-if="showSidenav"
      v-model="drawer"
      :menu="menu"
      :logo="node.settings.logo && node.settings.logo.main"
      :title="node.settings.title"
    />
    <Header
      v-if="showHeader"
      :user="user"
      :showCreateBtn="showCreateBtn"
      @handleNavIconClick="drawer = !drawer"
    />
    <Content>
      <router-view :key="getKey"></router-view>
    </Content>
    <Alert />
  </v-app>
</template>

<script>
import { rights } from "@/data/rights";
import { mapGetters, mapState } from "vuex";
import mixins from "./mixins.js";

import Content from "@/components/layouts/Content/Content.vue";
import Header from "@/components/layouts/Header/Header.vue";
import SideNav from "@/components/layouts/SideNav/SideNav.vue";
import {
  defaultAdminItems,
  defaultItems,
} from "@/data/ui/default/defaultMenuItems";
import { showCreateBtn } from "./guards";

export default {
  components: { SideNav, Header, Content },
  mixins: [mixins],
  data() {
    return {
      drawer: true,
    };
  },
  computed: {
    ...mapState("core$common", ["node", "user"]),
    ...mapGetters("core$rights", ["hasRight"]),
    getKey() {
      //  rerender page components when route params will change
      const { name, params } = this.$route;
      return name + JSON.stringify(params);
    },
    menu() {
      if (!this.node) return defaultItems;

      if (this.hasRight(rights.IsAdmin)) {
        return [...this.node.settings.menu.items, ...defaultAdminItems];
      }

      return [...this.node.settings.menu.items, ...defaultItems];
    },
    showCreateBtn,
  },
};
</script>
