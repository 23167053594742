import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("core$common", ["node", "boards"]),
    showSidenav() {
      if (this.node.settings.no_left_menu) return false;
      const boardName = this.$route.params.board;

      if (!boardName) return true;
      const currentBoard = this.boards.find(
        (board) => board.name === boardName
      );

      if (!currentBoard) return true;

      return !currentBoard.settings?.no_left_menu;
    },
    showHeader() {
      if (this.node.settings.no_top_menu) return false;
      const boardName = this.$route.params.board;

      if (!boardName) return true;
      const currentBoard = this.boards.find(
        (board) => board.name === boardName
      );

      if (!currentBoard) return true;

      return !currentBoard.settings?.no_top_menu;
    },
  },
};
