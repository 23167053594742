const defaultItems = [
  {
    type: "item",
    title: "ui_logout",
    subheader: null,
    is_active: false,
    icon: "mdi-logout",
    to: {
      name: "logout",
    },
  },
];

const defaultAdminItems = [
  {
    type: "item",
    title: "ui_settings",
    subheader: null,
    is_active: false,
    icon: "mdi-cog-outline",
    to: {
      name: "adminUsers",
    },
  },
  ...defaultItems,
];

export { defaultItems, defaultAdminItems };
